import React from 'react';
import {Avatar, Text, Paper, Space, ActionIcon, Group} from '@mantine/core';
import {IconBrandTwitter, IconBrandLinkedin, IconPlanet} from "@tabler/icons-react";
export default function MemberCard({ member }) {
  return (
    <Paper shadow="xs" radius="md" p="lg">
      <Avatar src={member.avatarUrl} size={120} radius={120} mx="auto" />
      <Text ta="center" fz="lg" weight={500} mt="md">
        {member.name}
      </Text>
      <Text ta="center" c="dimmed" weight={500} fz="sm">
        {member.role}
      </Text>
      <Space h="md" />
      <Text ta="center" c="dimmed" fz="sm">
        {member.description}
      </Text>
      <Group position="center" mt="md">
        <ActionIcon component="a" href={member.linkedin} disabled={!member.linkedin}>
          <IconBrandLinkedin stroke={1.5} size="1rem" />
        </ActionIcon>
        <ActionIcon component="a" href={member.x} disabled={!member.x}>
          <IconBrandTwitter stroke={1.5} size="1rem" />
        </ActionIcon>
        <ActionIcon component="a" href={member.website} disabled={!member.website}>
          <IconPlanet stroke={1.5} size="1rem" />
        </ActionIcon>
      </Group>
    </Paper>
  );
}
