import React from "react";
import {Grid, LoadingOverlay} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_IMAGES_BY_COMPANY_UUID} from "@/graphql/queries/AttachmentQueris";
import ImageCard from "~/components/PressKits/Images/ImageCard";
import {useTranslation} from "react-i18next";

export default function Images({ company }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_IMAGES_BY_COMPANY_UUID, { variables: { companyUuid: company.externalUuid } });

  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  const images = data.imagesByCompanyUuid

  return (
    <Grid mt={12}>
      {images.map(image => (

        <Grid.Col key={image.id} span={3}>
          <ImageCard
            attachment={image}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}