import React from "react";
import { Navbar, UnstyledButton } from "@mantine/core";
import {
  IconVideo,
  IconUsers,
  IconInfoOctagon,
  IconPictureInPicture,
  IconCubeSend,
  IconFileDescription,
  IconTool,
  IconGlass,
  IconCash
} from "@tabler/icons-react";
import CompanyCardWithImage from "./CompanyCardWithImage";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./NavbarCompanyView.module.scss";
import {useTranslation} from "react-i18next";

export default function NavbarCompanyView() {
  // const { classes, cx } = useStyles();
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    { link: "/about", label: t('layout.navbar.about'), icon: IconInfoOctagon },
    { link: "/team", label: t('layout.navbar.team'), icon: IconUsers },
    { link: "/press_releases", label: t('layout.navbar.press_releases'), icon: IconCubeSend },
    { link: "/images", label: t('layout.navbar.images'), icon: IconPictureInPicture },
    { link: "/videos", label: t('layout.navbar.videos'), icon: IconVideo },
    { link: "/documents", label: t('layout.navbar.documents'), icon: IconFileDescription },
    { link: "/services", label: t('layout.navbar.services'), icon: IconTool },
    { link: "/products", label: t('layout.navbar.products'), icon: IconGlass },
    { link: "/fundings", label: t('layout.navbar.fundings'), icon: IconCash },
  ];

  const mainLinks = links.map((link) => (
    <UnstyledButton
      key={link.label}
      className={classes.mainLink + " " + (location.pathname.endsWith(link.link) ? classes.linkActive : "")}
      onClick={() => navigate(`/companies/${id}${link.link}`)}
    >
      <div
        className={classes.mainLinkInner}
      >
        <link.icon
          size={20}
          className={classes.mainLinkIcon}
          stroke={1.5} />
        <span>{link.label}</span>
      </div>
    </UnstyledButton>
  ));

  return (
    <div
        className={classes.navbar}
    >
      <CompanyCardWithImage />

      <Navbar.Section
        // className={classes.section}
      >
        <div
          className={classes.mainLinks}
        >{mainLinks}</div>
      </Navbar.Section>
    </div>
  );
}
