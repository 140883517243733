import React from "react";
import {Grid, LoadingOverlay} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_PRESS_RELEASES_BY_COMPANY_UUID} from "@/graphql/queries/PressReleaseQueries";
import PressReleaseCard from "~/components/PressKits/PressReleases/PressReleaseCard";
import {useTranslation} from "react-i18next";

export default function PressReleases({ company }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_PRESS_RELEASES_BY_COMPANY_UUID, { variables: { companyUuid: company.externalUuid } });
  
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;
  const pressReleases = data.pressReleasesByCompanyUuid

  return (
    <Grid mt={12} justify="flex-start" align="stretch">
      {pressReleases.map(pressRelease => (
        <Grid.Col key={pressRelease.id} span={3}>
          <PressReleaseCard
            pressRelease={pressRelease}
            backUrl={`/presskits/${company.externalUuid}/press-releases`}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}