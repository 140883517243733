import React from 'react';
import {Avatar, Text, Flex} from '@mantine/core';

export default function ContactCard({ contact }) {
  return (
    <Flex gap="md">
      <Avatar src={contact.avatarUrl} alt={contact.name} size="lg" radius="xl" />
      <Flex direction="column">
        <Text>{contact.name}</Text>
        <Text c="dimmed" size="xs">{contact.email}</Text>
        <Text c="dimmed" size="xs">{contact.phone}</Text>
      </Flex>
    </Flex>
  );
}
