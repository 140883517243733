import React from "react";
import {Grid, LoadingOverlay} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_VIDEOS_BY_COMPANY_UUID} from "@/graphql/queries/AttachmentQueris";
import VideoCard from "~/components/PressKits/Video/VideoCard";
import {useTranslation} from "react-i18next";

export default function Video({ company }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_VIDEOS_BY_COMPANY_UUID, { variables: { companyUuid: company.externalUuid } });

  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;
  const videos = data.videosByCompanyUuid

  return (
    <Grid mt={12}>
      {videos.map(video => (
        <Grid.Col key={video.id} span={3}>
          <VideoCard
            attachment={video}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}