import React from "react";
import {Grid, LoadingOverlay, Paper} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_CONTACTS_BY_COMPANY_UUID} from "@/graphql/queries/UserQueries";
import {useTranslation} from "react-i18next";
import ContactCard from "./ContactCard";

export default function Contacts({ companyUuid }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_CONTACTS_BY_COMPANY_UUID, { variables: { companyUuid: companyUuid } });
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  const contacts = data.contactsByCompanyUuid

  return (
    <Paper shadow="xs" radius="md" p="xl" mt={12}>
      <h2>{t('press_kits.tabs.contacts')}</h2>
      <Grid mt={24} gutter="xl">
        {contacts.map(contact => (
          <Grid.Col key={contact.id} xs={12} sm={6} md={4} lg={4}>
            <ContactCard contact={contact} />
          </Grid.Col>
        ))}
      </Grid>
    </Paper>
  );
}