import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query AllCompanies($count: Int, $search: String) {
    allCompanies(count: $count, search: $search) {
      id
      name
      logoUrl
      tagline
      tags {
        id
        name
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query companies($after: String, $search: String, $filter: String, $selectedTagIds: [ID!]) {
    companies(after: $after, search: $search, filter: $filter, selectedTagIds: $selectedTagIds) {
      totalCount
      nodes {
        id
        name
        logoUrl
        tagline
        status
        externalUuid
        tags {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      logoUrl
      formationDate
      tagline
      officeAddress
      businessType
      turnover
      funding
      employeesCount
      webUrl
      facebookUrl
      linkedinUrl
      twitterUrl
      instagramUrl
      description
      descriptionMore
      externalUuid
      status
      tags {
        id
        name
      }
      keyPeople {
        __typename
        id
        name
        role
        avatarUrl
        linkedin
        x
        website
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($company: CompanyInput!) {
    createCompany(input: {company: $company}) {
      id
      name
      officeAddress
      description
      formationDate
      businessType
      turnover
      funding
      employeesCount
      webUrl
      facebookUrl
      linkedinUrl
      twitterUrl
      instagramUrl
      tagline
      descriptionMore
      tags {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_BY_EXTERNAL_UUID = gql`
  query CompanyByExternalUuid($externalUuid: String!) {
    companyByExternalUuid (externalUuid: $externalUuid) {
      id
      name
      logoUrl
      formationDate
      tagline
      officeAddress
      businessType
      turnover
      funding
      employeesCount
      webUrl
      facebookUrl
      linkedinUrl
      twitterUrl
      instagramUrl
      description
      descriptionMore
      externalUuid
      fundings {
        id
        name
        companyId
        numberOfInvestors
        moneyRaised
        leadInvestors
        date
      }
      tags {
        id
        name
      }
      keyPeople {
        __typename
        id
        name
        role
        avatarUrl
        linkedin
        x
        website
      }
    }
  }
`;
