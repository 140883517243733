import React from "react";
import {Grid, LoadingOverlay} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_DOCUMENTS_BY_COMPANY_UUID} from "@/graphql/queries/AttachmentQueris";
import DocumentCard from "~/components/PressKits/Documents/DocumentCard";
import {useTranslation} from "react-i18next";

export default function Documents({ company }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_DOCUMENTS_BY_COMPANY_UUID, { variables: { companyUuid: company.externalUuid } });
  
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;
  
  const documents = data.documentsByCompanyUuid

  return (
    <Grid mt={12}>
      {documents.map(document => (
        <Grid.Col key={document.id} span={3}>
          <DocumentCard
            attachment={document}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}